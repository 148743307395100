<template>
    <client-only>
        <v-navigation-drawer expand-on-hover permanent>
            <v-list v-model:opened="open" open-strategy="multiple"
            >
                <v-list-item href="https://denios.atlassian.net/wiki/spaces/ET/pages/3908173849/How+To+Settings+Service"
                             target="_blank"
                             prepend-icon="mdi-comment-question-outline" title="Help"/>
                <v-list-group value="Management">
                    <template v-slot:activator="{ props }">
                        <v-list-item
                                prepend-icon="mdi-tools"
                                title="Management"
                                v-bind="props"
                        ></v-list-item>
                    </template>
                    <v-list-item :to="{name: 'management-applications'}" prepend-icon="mdi-application-brackets-outline"
                                 title="Applications"/>
                    <v-list-item :to="{name: 'management-users'}" prepend-icon="mdi-account-circle" title="Users"/>

                </v-list-group>

                <v-list-group value="Applications">
                    <template v-slot:activator="{ props }">
                        <v-list-item
                                prepend-icon="mdi-application-brackets-outline"
                                title="Applications"
                                v-bind="props"
                        ></v-list-item>
                    </template>
                    <v-list-item
                            v-for="(navItem, i) of navItems"
                            :key="i"
                            :prepend-icon="navItem.icon"
                            :title="navItem.title"
                            :to="navItem.to"
                    />
                </v-list-group>
            </v-list>
        </v-navigation-drawer>
    </client-only>
</template>

<script setup lang="ts">
import type {RouteLocationRaw} from "vue-router";

const applicationManagementStore = useApplicationManagementStore();

type NavItem = {
    title: string;
    icon: string;
    to: RouteLocationRaw;
};

const applications = computed(() => {
    return applicationManagementStore.getAllApplications;
});

const navItems = computed(() => {
    return applications.value.map((app) => {
        return {
            title: app.name,
            icon: "mdi-xml",
            to: `/management/applications/${app.id}`,
        } as NavItem;
    });
});

const open = ref(['Applications', 'Management'])
</script>

<style scoped lang="scss"></style>
